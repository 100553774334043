@font-face {
    font-family: 'Proxima Nova Regular';
    src: url('../fonts/Proxima Nova Regular.otf'); 
}

@font-face {
    font-family: 'Proxima Nova Bold';
    src: url('../fonts/Proxima Nova Bold.otf'); 
}

@font-face {
    font-family: 'Proxima Nova Black';
    src: url('../fonts/Proxima Nova Black.otf'); 
}

@font-face {
    font-family: 'Proxima Nova Extrabold';
    src: url('../fonts/Proxima Nova Extrabold.otf'); 
}

@font-face {
    font-family: 'Proxima Nova Light';
    src: url('../fonts/Proxima Nova Light.otf'); 
}

@font-face {
    font-family: 'Rouble';
    src: url('../fonts/rouble.otf'); 
}